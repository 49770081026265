<template>
  <div>
    
    <div class="row align-items-end">
      <div class="col-3 my-3"> 
        <label for="">Client</label>
        <input type="text" :value="customer.fullName" :disabled="true" class="form-control" />
      </div>

      <div v-if="!this.$route.query.subscription" class="col-3 my-3">
        <label for=""> Réservations impayées </label>
        <select :disabled="this.$route.query.reservation" class="form-select" v-model="payment.booking_reference">
          <option value="" default></option>
          <option
            v-for="(reservation, index) in reservations"
            :key="index++"
            :value="reservation.reference"
            :style="{ color: 'red' }"
          >
            Nº {{ reservation.reference }} /
            <span class="col-auto">{{ reservation.date }}</span> /
            <span class="col-auto">{{ reservation.price }}</span>
          </option>
        </select>
      </div>

      <div v-if="!this.$route.query.reservation" class="col-3 my-3">
        <label for=""> Abonnements impayées </label>
        <select class="form-select" v-model="payment.subscription_reference">
          <option value="" default></option>
          <option
            v-for="(subscription, index) in subscriptions"
            :key="index++"
            :value="subscription.reference"
            :style="{ color: 'red' }"
          >
            Nº {{ subscription.reference }} /
            <span class="col-auto">{{ subscription.date }}</span> /
            <span class="col-auto">{{ subscription.price }}</span>
          </option>
        </select>
      </div>

      <div class="col-3 my-3">
        <label for="">Montant</label>
        <input type="text" v-model="payment.amount" class="form-control" />
      </div>

      <div class="col-3 my-3">
        <label for="">Date</label>
        <input type="date" :disabled="$userRole!='Admin'?true:false" v-model="payment.date" class="form-control" />
      </div>

      <div class="col-3 my-3">
        <label for="">Mode de paiement</label>
        <select v-model="payment.paymentMethod" class="form-select">
          <option value="Espèce">Espèce</option>
          <option value="Virement bancaire">Virement bancaire</option>
          <option value="Chèque">Chèque</option>
        </select>
      </div>

      <div class="col-3 my-3">
        <label for="">Numéro de chèque</label>
        <input type="text" v-model="payment.checkNumber" class="form-control" />
      </div>

      <div class="col-3 my-3">
        <label for="">Numéro de virement bancaire</label>
        <input type="text" v-model="payment.bankTransferNumber" class="form-control" />
      </div>

      <div class="col-3 my-3">
        <label for=""> Date de prochaine paiement </label>
        <input type="date" v-model="payment.nextPaymentDate" class="form-control" />
      </div>

      <div class="col-3 my-3">
        <label for=""> Montant de prochaine paiement </label>
        <input type="text" v-model="payment.nextPaymentAmount" class="form-control" />
      </div>

      <div class="col-12 my-3">
        <label for="">Remarques</label>
        <textarea class="form-control" cols="30" rows="3"></textarea>
      </div>
    </div>
    <br />
    <div class="row mt-3 justify-content-between">
      <div class="col-auto"></div>
      <div class="col-auto">
        <button class="btn btn-success" @click="save(payment)">
          <i class="bi bi-check-square-fill"></i> Enregistrer
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default { 
  data() {
    return {
      payment: {
        booking_reference: "",
        subscription_reference: "",
        amount: 0,
        nextPaymentAmount: 0,
        date: new Date(new Date().setFullYear(new Date().getFullYear()))
          .toLocaleString("en-CA")
          .split(",")[0],

        nextPaymentDate: new Date(new Date().setMonth(new Date().getMonth() + 1))
          .toLocaleDateString("en-CA"),
      },
    };
  },
  computed: {
    ...mapGetters("customer", {
      customer: "getCustomer",
    }),
    ...mapGetters("reservation", {
      reservations: "getReservations",
      reservationSelected: "getReservation"
    }),
    ...mapGetters("subscription", { subscriptions: "getSubscriptions" }),
  },
  watch: {
    
  },
  methods: {
    async save(data) {
      await (data.field_reference = this.$route.params.reference)
      await this.$store.dispatch("payment/store", data);
    },
  }, 
  async mounted() {
    if (!this.$route.params.reference) {
      await this.$router.push({ name: 'customers-index' });
    }
    await this.$store.dispatch("customer/show", this.$route.params.reference);

    if (this.$route.query.reservation) {
      await (this.payment.booking_reference=this.$route.query.reservation)
      await this.$store.dispatch("reservation/show", this.$route.query.reservation);
      await (this.payment.amount=this.reservationSelected.price)
    }
    if (this.$route.query.subscription) {
      await (this.payment.subscription_reference=this.$route.query.subscription)
    }

    
    
    await this.$store.dispatch("reservation/getCustomerUnpaid", this.$route.params.reference);
    await this.$store.dispatch("subscription/getCustomerUnpaid", this.$route.params.reference);
    
  },
};
</script>
